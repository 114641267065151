import { RouteComponentProps } from '@reach/router';
import { Link } from 'gatsby';
import * as React from 'react';
import logoDesktop from '../ontario-design-system/logos/ontario-logo--desktop.svg';
import './404.scss';

// NOTE: This page does not contain any React or JavaScript logic, since there is a bug running it in the hosted environment
// See the browser console in a hosted environment for more information
const NotFoundPage: React.FunctionComponent<RouteComponentProps> = () => {
  return (
    <div className="mdmp__not-found-page">
      <header className="mdmp__header">
        <div className="ontario-header" id="ontario-header-default">
          <div className="ontario-row">
            <div className="ontario-header__logo-container ontario-columns ontario-small-12">
              <a rel="noopener noreferrer" href="https://www.ontario.ca">
                <img
                  className="ontario-show-for-medium"
                  src={logoDesktop}
                  alt="Government of Ontario / Gouvernement de l’Ontario"
                  width="180"
                  height="46"
                />
              </a>
            </div>
          </div>
        </div>
      </header>
      <main>
        <title>Page not found / Page non trouvée</title>
        <div className="ontario-row">
          <div className="ontario-columns ontario-small-12">
            <h1>404</h1>
            <h2>
              Page not found
              <br />
              Page non trouvée
            </h2>
            <p>
              The page you are looking for doesn't exist or another error
              occurred.
              <br />
              La page que vous recherchez n'existe pas ou une autre erreur s'est
              produite.
            </p>
            <Link to="/" className="ontario-button ontario-button--primary">
              Return to home page
            </Link>
            <Link to="/fr/" className="ontario-button ontario-button--primary">
              Retour à la page d'accueil
            </Link>
          </div>
        </div>
      </main>
    </div>
  );
};

export default NotFoundPage;
