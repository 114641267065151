import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/common/breadcrumbs/Breadcrumbs';
import Layout from '../../components/layout/Layout';
import SideBar from '../../components/layout/SideBar';
import { getPageLink } from '../../constants';
import { GlobalStateContext } from '../../context/GlobalContextProvider';
import imageEditProfile from '../../images/edit-profile.svg';
import { timeConverter } from '../../util/utils';
import './UserProfilePage.scss';
import { PageContentType } from '../../context/PageContentType';

/**
 *
 * @param props
 * @constructor
 */
const UserProfilePage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const state = useContext(GlobalStateContext);
  const user = state.userProfile;

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.userProfile'),
      path: getPageLink(props.pageContext.lang, 'USER', 'USER_PROFILE'),
    },
  ];

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'USER', 'USER_PROFILE')}
      pageTitle={t('userProfilePage.title')}
    >
      <div className="ontario-row mdmp__user-profile">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <div className={'mdmp__user-profile-title'}>
            <h1>{t('userProfilePage.title')}</h1>
            <button
              className={'mdmp__edit-profile-icon'}
              onClick={() => {
                navigate(
                  getPageLink(
                    props.pageContext.lang,
                    'USER',
                    'USER_PROFILE_EDIT',
                  ),
                );
              }}
            >
              <img
                alt={t('userProfilePage.editProfile')}
                src={imageEditProfile}
              />
            </button>
          </div>

          <div className={'mdmp__user-profile-fields'}>
            <p>
              {t('userProfilePage.fields.firstName')} {user.firstName}
            </p>
            <p>
              {t('userProfilePage.fields.lastName')} {user.lastName}
            </p>
            <p>
              {t('userProfilePage.fields.company')} {user.company}
            </p>
            <p>
              {t('userProfilePage.fields.phone')} {user.phone}
            </p>
            <p>
              {t('userProfilePage.fields.isAuthorized')}{' '}
              {user.isAuthorized
                ? t('userProfilePage.isAuthorized.yes')
                : t('userProfilePage.isAuthorized.no')}
            </p>
            <p>
              {t('userProfilePage.fields.registeredOn')}{' '}
              {timeConverter(user.registeredOn)}
            </p>
            <br />
            <p>
              {t('userProfilePage.fields.authorizedRequesterNumber')}{' '}
              {user.authorizedRequestorNumber}
            </p>
          </div>

          <hr />
          <h2>{t('userProfilePage.overview')}</h2>
          <Link to={'#'}>{t('userProfilePage.viewMyCurrentProducts')}</Link>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default UserProfilePage;
