import React from 'react';
import { Router } from '@reach/router';
import SecureRoutes from '../../components/secure-routes/secure-routes';
import { graphql } from 'gatsby';
import { SITE_CONFIG, getPageLink, LANG_CONFIG } from '../../constants';
import UserProfilePage from './UserProfilePage';
import UserProfileEditPage from './UserProfileEditPage';
import NotFoundPage from '../../pages/404';
import { PageContentType } from '../../context/PageContentType';

/**
 * This page holds the authorized routes for the user endpoint.
 */
const UserPage: React.FunctionComponent<PageContentType> = props => {
  /**
   * This next line is VERY important, as it solves a server-side rendering issue for client-only pages
   * Without this line, the page will not render properly if accessed directly (i.e. refreshing the page)
   * See the README for more information
   */
  if (typeof window === 'undefined') return null;
  return (
    <Router
      basepath={
        LANG_CONFIG[props.pageContext.lang.toUpperCase() + '_Prefix'] +
        SITE_CONFIG.USER[props.pageContext.lang.toUpperCase()]
      }
    >
      <SecureRoutes path="/">
        <>
          <UserProfilePage
            path={
              SITE_CONFIG.USER.PAGES.USER_PROFILE[
                props.pageContext.lang.toUpperCase()
              ]
            }
            pageContext={{
              currentLangUrl: getPageLink(
                props.pageContext.lang,
                'USER',
                'USER_PROFILE',
              ),
              otherLangUrl: getPageLink(
                props.pageContext.otherLang,
                'USER',
                'USER_PROFILE',
              ),
              lang: props.pageContext.lang,
              otherLang: props.pageContext.otherLang,
            }}
          />
          <UserProfileEditPage
            path={
              SITE_CONFIG.USER.PAGES.USER_PROFILE_EDIT[
                props.pageContext.lang.toUpperCase()
              ]
            }
            pageContext={{
              currentLangUrl: getPageLink(
                props.pageContext.lang,
                'USER',
                'USER_PROFILE_EDIT',
              ),
              otherLangUrl: getPageLink(
                props.pageContext.otherLang,
                'USER',
                'USER_PROFILE_EDIT',
              ),
              lang: props.pageContext.lang,
              otherLang: props.pageContext.otherLang,
            }}
          />
          <NotFoundPage default />
        </>
      </SecureRoutes>
    </Router>
  );
};

export default UserPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
