import * as React from 'react';
import { useTranslation } from 'react-i18next';
import alertIcon from '../../images/ontario-icon-alert-error.svg';

type Props = {
  errors: string[];
};

/**
 * Renders critical errors
 *
 * @param props
 * @constructor
 */
const CriticalPageError: React.FunctionComponent<Props> = props => {
  const { t } = useTranslation();

  return props.errors?.length ? (
    <div className="ontario-alert ontario-alert--error" id="error-holder">
      <div className="ontario-alert__header">
        <div className="ontario-alert__header-icon">
          <img
            src={alertIcon}
            alt={t('error.alertIcon')}
            width="33"
            height="33"
          />
        </div>
        <h2 className="ontario-alert__header-title ontario-h4">
          {t('error.thereIsProblem')}
        </h2>
      </div>
      <div className="ontario-alert__body">
        <ul>
          {props.errors.map((v, k) => {
            return <li key={k}>{t('error.' + v)}</li>;
          })}
        </ul>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default CriticalPageError;
