import { gql, useMutation } from '@apollo/client';
import { Link, navigate } from 'gatsby';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Breadcrumbs from '../../components/common/breadcrumbs/Breadcrumbs';
import CriticalPageError from '../../components/error/CriticalPageError';
import Layout from '../../components/layout/Layout';
import SideBar from '../../components/layout/SideBar';
import {
  ERROR_ENTITY,
  getPageLink,
  GQL_EDIT_USER_PROFILE,
} from '../../constants';
import {
  SET_LOADING_OFF,
  SET_LOADING_ON,
  SET_USER_INFO,
} from '../../context/actions/ActionTypes';
import {
  GlobalDispatchContext,
  GlobalStateContext,
} from '../../context/GlobalContextProvider';
import { errorHandler, showUnexpectedError } from '../../errorHandler';
import imageEditProfileDisabled from '../../images/edit-profile-disabled.svg';
import {
  adjustValueForInput,
  checkField,
  focusElement,
} from '../../util/utils';
import './UserProfilePage.scss';
import { PageContentType } from '../../context/PageContentType';

/**
 *
 * @param props
 * @constructor
 */
const UserProfilePage: React.FunctionComponent<PageContentType> = props => {
  const { t } = useTranslation();

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);
  const user = state.userProfile;

  const [userProfileErrors, setUserProfileErrors] = useState([]);

  const bc = [
    { label: 'home' },
    { label: 'chevron' },
    {
      label: t('breadcrumbs.editUserProfile'),
      path: getPageLink(props.pageContext.lang, 'USER', 'USER_PROFILE_EDIT'),
    },
  ];

  const [
    editUserProfile,
    {
      loading: editUserProfileLoading,
      error: editUserProfileError,
      data: editUserProfileData,
    },
  ] = useMutation(
    gql`
      ${GQL_EDIT_USER_PROFILE}
    `,
    {
      variables: {
        user: {
          firstName: user.firstName !== '' ? user.firstName : null,
          lastName: user.lastName !== '' ? user.lastName : null,
          company: user.company !== '' ? user.company : null,
          phone: user.phone !== '' ? user.phone : null,
          authorizedRequestorNumber:
            user.authorizedRequestorNumber !== ''
              ? user.authorizedRequestorNumber
              : null,
        },
      },
      errorPolicy: 'all',
    },
  );

  const doEditProfile = () => {
    const errors = [];

    if (!checkField(user.firstName, 255)) {
      errors.push('INVALID_FIRST_NAME');
    }

    if (!checkField(user.lastName, 255)) {
      errors.push('INVALID_LAST_NAME');
    }

    if (!checkField(user.company, 255)) {
      errors.push('INVALID_COMPANY');
    }

    if (!checkField(user.phone, 50)) {
      errors.push('INVALID_PHONE');
    }

    if (!checkField(user.authorizedRequestorNumber, 10)) {
      errors.push('INVALID_AUTHORIZED_REQUESTER_NUMBER');
    }
    // else {
    //   if (
    //     !checkAuthorizedRequestorNumberFormat(user.authorizedRequestorNumber)
    //   ) {
    //     errors.push('INVALID_FORMAT_AUTHORIZED_REQUESTER_NUMBER');
    //   }
    // }

    setUserProfileErrors(errors);

    if (!errors.length) {
      editUserProfile();
    } else {
      setTimeout(() => focusElement('#error-holder'), 0);
    }

    return;
  };

  useEffect(() => {
    if (editUserProfileLoading) {
      dispatch({
        type: SET_LOADING_ON,
      });
      return;
    } else {
      dispatch({
        type: SET_LOADING_OFF,
      });
    }

    if (editUserProfileError) {
      errorHandler(
        dispatch,
        t,
        editUserProfileError,
        ERROR_ENTITY.EDIT_USER_PROFILE,
        props.pageContext.lang,
      );

      return;
    }

    if (editUserProfileData) {
      if (editUserProfileData?.setUserProfile) {
        navigate(getPageLink(props.pageContext.lang, 'USER', 'USER_PROFILE'));

        return;
      }

      // Handling unexpected error (should never happen)
      showUnexpectedError(dispatch);

      return;
    }
  }, [editUserProfileLoading, editUserProfileData, editUserProfileError]);

  return (
    <Layout
      lang={props.pageContext.lang}
      currentLangUrl={props.pageContext.currentLangUrl}
      otherLangUrl={props.pageContext.otherLangUrl}
      pageId={getPageLink(props.pageContext.lang, 'USER', 'USER_PROFILE_EDIT')}
      pageTitle={t('userProfilePage.title')}
    >
      <div className="ontario-row mdmp__user-profile">
        <div className="ontario-columns ontario-small-12 ontario-medium-9">
          <Breadcrumbs bc={bc} lang={props.pageContext.lang} />

          <div className={'mdmp__user-profile-title'}>
            <h1>{t('userProfilePage.title')}</h1>
            <div className={'mdmp__edit-profile-icon-disabled'}>
              <img
                alt={t('userProfilePage.editProfile')}
                src={imageEditProfileDisabled}
              />
            </div>
          </div>

          <>
            <CriticalPageError errors={userProfileErrors} />

            <div className={'mdmp__user-profile-fields'}>
              <div className="ontario-form-group">
                <label className="ontario-label" htmlFor="mdmp__first-name">
                  {t('userProfilePage.fields.firstName')}
                </label>
                <input
                  className="ontario-input ontario-input--20-char-width"
                  type="text"
                  id="mdmp__first-name"
                  value={adjustValueForInput(user.firstName)}
                  onChange={e => {
                    dispatch({
                      type: SET_USER_INFO,
                      userProfile: {
                        ...user,
                        firstName: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="ontario-form-group">
                <label className="ontario-label" htmlFor="mdmp__last-name">
                  {t('userProfilePage.fields.lastName')}
                </label>
                <input
                  className="ontario-input ontario-input--20-char-width"
                  type="text"
                  id="mdmp__last-name"
                  value={adjustValueForInput(user.lastName)}
                  onChange={e => {
                    dispatch({
                      type: SET_USER_INFO,
                      userProfile: {
                        ...user,
                        lastName: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="ontario-form-group">
                <label className="ontario-label" htmlFor="mdmp__company">
                  {t('userProfilePage.fields.company')}
                </label>
                <input
                  className="ontario-input ontario-input--20-char-width"
                  type="text"
                  id="mdmp__company"
                  value={adjustValueForInput(user.company)}
                  onChange={e => {
                    dispatch({
                      type: SET_USER_INFO,
                      userProfile: {
                        ...user,
                        company: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="ontario-form-group">
                <label className="ontario-label" htmlFor="mdmp__phone">
                  {t('userProfilePage.fields.phone')}
                </label>

                <input
                  className="ontario-input ontario-input--20-char-width"
                  type="text"
                  id="mdmp__phone"
                  value={adjustValueForInput(user.phone)}
                  onChange={e => {
                    dispatch({
                      type: SET_USER_INFO,
                      userProfile: {
                        ...user,
                        phone: e.target.value,
                      },
                    });
                  }}
                />
              </div>

              <div className="ontario-form-group">
                <label
                  className="ontario-label"
                  htmlFor="mdmp__authorized-requester-number"
                >
                  {t('userProfilePage.fields.authorizedRequesterNumber')}
                </label>

                <input
                  className="ontario-input ontario-input--20-char-width"
                  type="text"
                  id="mdmp__authorized-requester-number"
                  value={adjustValueForInput(user.authorizedRequestorNumber)}
                  onChange={e => {
                    dispatch({
                      type: SET_USER_INFO,
                      userProfile: {
                        ...user,
                        authorizedRequestorNumber: e.target.value,
                      },
                      updateAuthorizedRequestorNumberStatic: false,
                    });
                  }}
                />
              </div>

              <div className={'mdmp__user-profile-buttons'}>
                <button
                  className="ontario-button ontario-button--secondary"
                  onClick={() => {
                    navigate(
                      getPageLink(
                        props.pageContext.lang,
                        'USER',
                        'USER_PROFILE',
                      ),
                    );
                  }}
                >
                  {t('buttons.cancel')}
                </button>

                <button
                  className="ontario-button ontario-button--primary"
                  onClick={() => {
                    doEditProfile();
                  }}
                >
                  {t('buttons.update')}
                </button>
              </div>
            </div>
          </>

          <hr />
          <h2>{t('userProfilePage.overview')}</h2>
          <Link to={'#'}>{t('userProfilePage.viewMyCurrentProducts')}</Link>
        </div>

        <SideBar />
      </div>
    </Layout>
  );
};

export default UserProfilePage;
